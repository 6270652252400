<template>
  <!--  <div >-->
  <vueScroll id="search-results" ref="searchResults" :ops="ops" class="main-scroll">

    <!--    <h1>Your results for: {{ query }}</h1>-->

    <div class="results-section servers-results">
      <div class="search-result-heading" style="padding-bottom: 0; padding-top: 24px" v-if="servers.length"><h3>Servers <small
          style="color: #5c5c5c;">Results</small></h3></div>
      <h3 class="search-result-heading" v-if="!servers.length">No Servers Found</h3>
      <div id="search-list" ref="searchList" v-if="servers && servers.length">
        <div style="height: 100%; max-height: 100%; width: 100%; overflow: hidden">
          <!--vuescroll id="browse-list-scroll"  :ops="ops" -->
          <BrowseServersChild
              class="browse-single column"
              :style="{ 'width': itemCol+'%', 'max-width': itemCol+'%' }"
              v-for="(server,i) in serversForShow"
              :key="i"
              :server="server"
          ></BrowseServersChild>
          <p v-if="loadMoreButton" id="search-load-more" ref="loadMore">
            <b-button @click="handleLoadMore" :loading="loading">Show All</b-button>
          </p>
        </div>
      </div>

    </div>

    <div class="columns results-section">
      <div class="column is-half rooms-results">
        <div class="search-result-heading" v-if="servers.length"><h3>Rooms <small
            style="color: #5c5c5c;">Results</small></h3></div>
        <h3 class="search-result-heading" v-if="!rooms.length">No Rooms Found</h3>
        <a
            v-for="room in rooms"
            :key="room._id"
            @click.prevent="goToRoom(room)"
        >
          <div class="row vcentered search-result">
            <div class="search-result-thumb"
                 :style="{ 'background-image': `url(${setThumb(room.server.image)})` }"
            ></div>
            <div class="search-result-text">
              <p class="title">{{ room.name }}</p>
              <p class="subtitle">{{ room.subscriptions.length }} members</p>
            </div>
          </div>
        </a>
      </div>

      <div class="column is-half users-results">
        <div class="search-result-heading" v-if="servers.length"><h3>Users <small
            style="color: #5c5c5c;">Results</small></h3></div>
        <h3 class="search-result-heading" v-if="!users.length">No Users Found</h3>
        <a
            v-for="user in users"
            :key="user._id"
            @click.prevent="goToUserProfile(user.username)"
        >
          <div class="row vcentered search-result">
            <div class="search-result-thumb">
              <img class="search-result-image" v-if="user.image" :src="`${baseUrl}/${user.image}`" alt="user-image"/>
              <img class="search-result-image" v-else :src="setThumb(user.image)" alt="user-image"/>
            </div>
            <div class="search-result-text">
              <p class="title">{{ user.username }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </vueScroll>
  <!--  </div>-->
</template>

<script>
import axios from '@/axios';
import vueScroll from "vuescroll";
import BrowseServersChild from "../components/BrowseCore/BrowseServersChild";
// import {ResizeObserver} from "vue-resize";

export default {
  name: 'SearchResults',
  props: [
    'query',
  ],
  components: {
    BrowseServersChild,
    vueScroll,
    // 'resize-observer': ResizeObserver,

  },
  data() {
    return {
      loadMoreButton: false,
      serversForShow: [],
      browseWidth: null,
      showCarousel: true,
      itemCol: 33,
      servers: [],
      rooms: [],
      users: [],
      page: 1,
      totalPages: 1,
      totalItems: 0,
      loading: true,
      errors: [],
      baseUrl: '',
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: false,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {},
      },
    }
  },
  watch: {
    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.emptyData();
        this.init();
      }
    },
    browseWidth(newval, oldval) {
      console.log('browseWidth', newval);
      if (newval > 1600) {
        this.columns = 4;
      } else if (newval <= 992 && newval > 530) {
        this.columns = 2;
      } else if (newval <= 530) {
        this.columns = 1;
      } else {
        this.columns = 3;
      }
      this.itemCol = parseFloat((100 / this.columns) - 0.5);
    },
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_API_IMAGES_URL;
    await this.init();
  },
  mounted() {
    this.handleResize();

    // const sheet = this.$watch(
    //     () => {
    //       return this.$refs.swipeableBottomSheetHistory.state;
    //     },
    //     (state) => {
    //       this.bottomSheet = state;
    //     }
    // );
  },
  computed: {
    userServers() {
      return this.$store.getters.get_userServers;
    },
    serverThumbDefault() {
      return require('@/assets/img/server-thumb-default.png');
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async init() {
      this.totalItems = 0;

      axios.get(`/servers/search/${this.query}`)
          .then(({data}) => {
            console.log('servers', data.length);
            this.totalItems = this.totalItems + data.length;
            //data.forEach((item) => this.data.push(item))
            data.forEach(item => {
              this.servers.push(item);
            });
            this.serversForShow = this.servers.slice(0, 4);
            (this.servers.length > 4) ? this.loadMoreButton = true : this.loadMoreButton = false;
            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            this.errors.push(error);
            throw error;
          })

      axios.get(`/rooms/search/${this.query}`)
          .then(({data}) => {
            console.log('rooms', data.length);
            this.totalItems = this.totalItems + data.length;
            data.forEach(item => {
              if (!item.is_private && !item.name.includes('Broadcast')) {
                this.rooms.push(item);
              }
            });

            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            this.errors.push(error);
            throw error;
          })

      axios.get(`/users/search/${this.query}`)
          .then(({data}) => {
            console.log('users', data.length);
            this.totalItems = this.totalItems + data.length;
            data.forEach(item => {
              this.users.push(item);
            });
            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            this.errors.push(error);
            throw error;
          })
          .finally(() => {
            this.loading = false
          });
    },
    goToServer(server) {
      if (this.isAuthenticated) {
        const id = server._id;
        if (this.userServers[id]) {
          this.$router.push({name: 'Chat', params: {id: server.slug, action: 'visit', server_id: server._id}});
        } else {
          this.$router.push({name: 'Chat', params: {id: server.slug, action: 'join', server_id: server._id}});
        }

        //this.$router.replace('/servers/' + server.slug);
        //this.$store.commit('set_selectedServer', server);
      } else {
        this.$store.commit('set_openCloseLogIn');
      }
    },
    goToUserProfile(username) {
      this.$router.push(`/user-profile/${username.toLowerCase()}`)
    },
    async goToRoom(room) {
      // this.resetSearch();
      if (!this.isAuthenticated) {
        this.$store.commit('set_openCloseLogIn');
        return;
      }
      let server;
      const uri = `/servers/${room.server.slug}/${room.slug}`;
      try {
        let {data} = await axios.get('/servers/slug/' + room.server.slug);
        server = data;
      } catch (e) {
        console.log('error:', e);
      }
      if (!server) {
        return false;
      }
      // console.log('goToRoom server', server);
      const request = {
        serverId: room.server._id,
        roomId: room._id,
        server: server,
        searchRequest: false,
        action: 'search'
      }

      if (this.$route.name !== 'Chat') {
        this.$router.push(uri);
      } else {
        if (this.$route.path !== uri) {
          this.$router.push(uri);
        }
      }

      setTimeout(() => {
        this.$store.commit('set_autoSelect', null);
        this.$store.commit('set_selectedRoom', null);
        this.$store.commit('set_selectedRoomMessages', []);
        this.$store.commit('set_selectedRoomGroups', []);
        this.$store.commit('set_previousMessagesIndex', null);
        this.$store.commit('set_nextMessagesIndex', null);
        this.$store.commit('set_autoSelect', request);
      }, 200);

    },
    emptyData() {
      this.servers = [];
      this.rooms = [];
      this.users = [];
      this.page = 1;
      this.totalPages = 1;
      this.totalItems = 0;
      this.loading = true;
      this.errors = [];
    },
    setThumb(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('server' === image.slice(0, 6)) {
            return this.$imageurl + '/' + image;
          } else {
            return image;
          }
        }
      }
      return this.serverThumbDefault;
    },
    handleResize() {
      this.browseWidth = this.$refs.searchResults.clientWidth;
    },
    setBrowseWidth() {
      let main = document.getElementById('browse');
      if (main) {
        //console.log('main.offsetWidth', main.offsetWidth)
        this.browseWidth = main.offsetWidth;
      } else {
        this.browseWidth = 900;
      }
    },
    handleLoadMore() {
      this.serversForShow = this.servers;
      this.loadMoreButton = false;
    }
  }
}
</script>

<style scoped>

#search-results {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

}


/*#browse-list {*/
/*  height: 99%;*/
/*  max-height: 99%;*/
/*  overflow: hidden;*/
/*  display: inline-block;*/
/*  width: 100%;*/
/*  white-space: normal;*/
/*  padding: 0 22px !important;*/
/*  margin: 0;*/
/*}*/

/*#browse-list.has-carousel {*/
/*  height: calc(100% - 460px) !important;*/
/*  max-height: calc(100% - 460px) !important;*/
/*}*/

.results-section {
  /*padding-bottom: 30px;*/
  /*flex-direction: column;*/
  /*display: flex;*/
  padding: 6px;
}

.search-result-heading {
  display: inline-block;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  color: #ffffff;
  padding-bottom: 25px;
}

ul#tags li {
  display: inline;
}

.search-result {
  border: 1px solid transparent;
  width: 260px;
  height: auto;
  margin-bottom: 10px;
  padding: 12px 4px;
  border-radius: 6px;
}

.search-result:hover {
  background-color: #303538;
}


.search-result-thumb {
  width: 35px;
  height: 35px;
  border-radius: 13px;
  background-color: #232728;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 3px;
}

.search-result-text {
  font-family: 'Circular Std Book', sans-serif;
  margin-top: 2px;
  margin-left: 9px;
}

.search-result-text .title {
  color: #CCCBCB;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Circular Std Book', sans-serif;
  margin: 0;
  width: 100%;
}

.search-result-text .subtitle {
  font-family: 'Circular Std Book', sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  width: 100%;
  letter-spacing: -0.34px;
}


.search-result-image {
  width: 35px;
  height: 35px;
  left: 0.65%;
  right: 84.27%;
  top: 1.43%;
  bottom: 48.57%;
  cursor: pointer;

  border-radius: 13px;
}

.search-result-name {
  width: 80px;
  height: 20px;
  top: calc(50% - 20px / 2 - 25px);
  color: #FFFFFF;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.533333px;
}

.search-result-members {
  height: 18px;
  left: 43px;
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.337647px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.search-result-categories {
  left: 0%;
  right: 63.36%;
  top: 66.35%;
  bottom: 0.64%;
  background: #3E4143;
  border-radius: 13px;
}

.search-result-categories-tag {
  height: 15px;
  left: 5.17%;
  right: 68.1%;
  top: calc(50% - 15px / 2 + 21.5px);
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.4px;
  color: #D4D4D4;
}

.search-results-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-result-p {
  padding-left: 25px;
}

#search-load-more {
  width: 100%;
  text-align: center;
  margin: 1em 0 3em;
}


#search-load-more button {
  background-color: #303538;
  border-color: #303538;
  color: #797979;
}

</style>
